<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Food Merchant Category</h4>
    </vs-row>

    <vs-row vs-w="6">
      <vs-col vs-w="12" class="mb-3">
        <vs-input
          class="w-full"
          label="Category Name (*)"
          v-model="payload.name"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("name")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2" :disabled="loading">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";
// Store Module
import foodMerchantCategoryStore from "@/store/food-merchant-category";

export default {
  metaInfo: {
    title: "Add Bus Feature",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    vSelect,
  },

  data: () => ({
    payload: {
      name: "",
      feature_category_id: "",
    },
    featureCategoryOptions: [],
    loading: false,
  }),

  methods: {
    getFeatureCategories() {
      axios
        .get("/feature-category")
        .then(({ data: res }) => {
          this.featureCategoryOptions = res.data.map((category) => {
            return {
              id: category.id,
              name: category.name,
            };
          });
        })
        .catch((err) => this.$catchErrorResponse(err));
    },
    async storeData() {
      const formData = new FormData();
      formData.set("name", this.payload.name);
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "foodMerchantCategory/storeFoodMerchantCategory",
          formData
        );
        this.loading = false;
        if (!response.success) {
          throw error(response.message);
        }
        this.$vs.notify({
          title: "Successful",
          text: response.msg_status,
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
        });

        this.$router.push("/food-merchant-category");
      } catch (error) {
        console.log(error);
        this.$catchErrorResponse(error);
      }
    },
  },

  created() {
    if (!foodMerchantCategoryStore.isRegistered) {
      this.$store.registerModule(
        "foodMerchantCategory",
        foodMerchantCategoryStore
      );
      foodMerchantCategoryStore.isRegistered = true;
    }
    this.getFeatureCategories();
  },
};
</script>

<style></style>
